import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import Video from "@/models/Video";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";
import axios from "axios";
import Response from "@/models/responses/Response";

export default class VideoService {


    static postVideo(component: Vue, video: Video) {
        (<any>component).loading = true
        component.axios.post(ConstantTool.BASE_URL + "/api/video",
            video, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let item = JsonTool.jsonConvert.deserializeObject(response.data, Video)
                component.$router.push({path: "/video/" + item.id})
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo crear el video"))
            .finally(() => (<any>component).loading = false)
    }

    static getVideos(component: Vue, videos: Video[]) {
        (<any>component).loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/video", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let list = JsonTool.jsonConvert.deserializeArray(response.data, Video)
                videos.splice(0, videos.length)
                list.forEach(v => videos.push(v))
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se han podido obtener los videos"))
            .finally(() => (<any>component).loading = false)
    }

    static async getVideo2(id: number): Promise<Response<Video>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/video/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const video = JsonTool.jsonConvert.deserializeObject(response.data, Video)
            return Promise.resolve({ result: video })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async patchVideo(id: number, request: Video): Promise<Response<Video>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/video/" + id, request, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const video = JsonTool.jsonConvert.deserializeObject(response.data, Video)
            return Promise.resolve({ result: video })
        } catch (e) { return Promise.reject(e) }
    }

    static async getVideos2(page: number, size: number, search: Nullable<string>): Promise<Response<Video[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/video", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { page, size, search }
            })
            const videos = JsonTool.jsonConvert.deserializeArray(response.data, Video)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: videos, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async setVideoStatus(id: number, enabled: boolean) {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/video/${id}/status`, null, {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { enabled }
            })
            const video = JsonTool.jsonConvert.deserializeObject(response.data, Video)
            return Promise.resolve({ result: video })
        } catch (e) { return Promise.reject(e) }
    }

    static async getPublicVideos(page: number, size: number, search: Nullable<string>): Promise<Response<Video[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/public/video", {
                params: { page, size, search }
            })
            const videos = JsonTool.jsonConvert.deserializeArray(response.data, Video)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: videos, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

}
