


































import {Component, Ref, Vue} from "vue-property-decorator";
import Video from "@/models/Video";
import VideoService from "@/services/VideoService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import Handler from "@/handlers/Handler";
import {SingleItem} from "@/handlers/interfaces/ContentUI";

@Component
export default class VideoView extends Vue {
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	video: Video = new Video()
	video2: SingleItem<Video> = { item: new Video() }
	loading: boolean = false
	urlRules = [
		(v: string) => v && v.length > 0 ? true : this.lang.urlRequired,
		(v: string) => StringTool.validateUrl(v) ? true : this.lang.urlNoValid
	]

	created() {
		this.refresh()
	}

	async refresh() {
		await Handler.getItem(this, this.video2, () =>
			VideoService.getVideo2(Number(this.$route.params.videoId))
		)
	}

	updateVideo() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.videoUpdateQuestion,
				async () => {
					await Handler.getItem(this, this.video2, () => VideoService.patchVideo(Number(this.$route.params.videoId), this.video2.item))
				}
			))
		}
	}

	setStatus() {
		getModule(DialogModule).showDialog(new Dialog(this.lang.warning, "¿Desea cambiar el estado del video?",
			async () => {
				await Handler.getItem(this, this.video2, () => VideoService.setVideoStatus(Number(this.$route.params.videoId), !this.video2.item.enabled!!))
			}
		))
	}
}
